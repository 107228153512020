import React from 'react';
import './Shapes.scss';

import { fabric } from 'fabric';

import __ from './../utils/translation';

// Pumpkins
import { ReactComponent as Shape01 } from './../shapes/pumpkins/1.svg';
import { ReactComponent as Shape02 } from './../shapes/pumpkins/2.svg';
import { ReactComponent as Shape03 } from './../shapes/pumpkins/3.svg';
import { ReactComponent as Shape04 } from './../shapes/pumpkins/4.svg';
import { ReactComponent as Shape05 } from './../shapes/pumpkins/5.svg';

// Eyes
import { ReactComponent as Shape06 } from './../shapes/eyes/1.svg';
import { ReactComponent as Shape07 } from './../shapes/eyes/2.svg';
import { ReactComponent as Shape08 } from './../shapes/eyes/3.svg';
import { ReactComponent as Shape09 } from './../shapes/eyes/4.svg';
import { ReactComponent as Shape10 } from './../shapes/eyes/5.svg';
import { ReactComponent as Shape11 } from './../shapes/eyes/6.svg';
import { ReactComponent as Shape12 } from './../shapes/eyes/7.svg';
import { ReactComponent as Shape13 } from './../shapes/eyes/8.svg';
import { ReactComponent as Shape14 } from './../shapes/eyes/9.svg';
import { ReactComponent as Shape15 } from './../shapes/eyes/10.svg';

// Noses
import { ReactComponent as Shape16 } from './../shapes/noses/1.svg';
import { ReactComponent as Shape17 } from './../shapes/noses/2.svg';
import { ReactComponent as Shape18 } from './../shapes/noses/3.svg';
import { ReactComponent as Shape19 } from './../shapes/noses/4.svg';
import { ReactComponent as Shape20 } from './../shapes/noses/5.svg';
import { ReactComponent as Shape21 } from './../shapes/noses/6.svg';
import { ReactComponent as Shape22 } from './../shapes/noses/7.svg';
import { ReactComponent as Shape23 } from './../shapes/noses/8.svg';
import { ReactComponent as Shape24 } from './../shapes/noses/9.svg';
import { ReactComponent as Shape25 } from './../shapes/noses/10.svg';

// Mouths
import { ReactComponent as Shape26 } from './../shapes/mouths/1.svg';
import { ReactComponent as Shape27 } from './../shapes/mouths/2.svg';
import { ReactComponent as Shape28 } from './../shapes/mouths/3.svg';
import { ReactComponent as Shape29 } from './../shapes/mouths/4.svg';
import { ReactComponent as Shape30 } from './../shapes/mouths/5.svg';
import { ReactComponent as Shape31 } from './../shapes/mouths/6.svg';
import { ReactComponent as Shape32 } from './../shapes/mouths/7.svg';
import { ReactComponent as Shape33 } from './../shapes/mouths/8.svg';
import { ReactComponent as Shape34 } from './../shapes/mouths/9.svg';
import { ReactComponent as Shape35 } from './../shapes/mouths/10.svg';

const Shapes = ({ canvas }) => {

  const handleShapeAdd = (e) => {
    fabric.loadSVGFromString(
      e.currentTarget.children[0].outerHTML,
      (objects, options) => {
        var obj = fabric.util.groupSVGElements(objects, options)
        obj.strokeUniform = true
        obj.strokeLineJoin = 'miter'
        obj.scaleToWidth(100)
        obj.scaleToHeight(100)
        obj.set({ left: 0, top: 0 })
        canvas.add(obj).renderAll()
        canvas.trigger('object:modified')
      }
    )
  }


  return (
    <>
      <p className="title">{__('SHAPES')}</p>

      <div className="shapes">
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape01 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape02 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape03 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape04 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape05 /></div>
        <hr className="hr-header" data-text="Eyes" style={{display:'block',width:'100%',gridColumnStart:1,gridColumnEnd:-1,height:'1px',border:'none',background:'#DDD' }}></hr>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape06 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape07 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape08 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape09 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape10 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape11 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape12 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape13 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape14 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape15 /></div>
        <hr className="hr-header" data-text="Noses" style={{display:'block',width:'100%',gridColumnStart:1,gridColumnEnd:-1,height:'1px',border:'none',background:'#DDD' }}></hr>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape16 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape17 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape18 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape19 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape20 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape21 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape22 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape23 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape24 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape25 /></div>
        <hr className="hr-header" data-text="Mouths" style={{display:'block',width:'100%',gridColumnStart:1,gridColumnEnd:-1,height:'1px',border:'none',background:'#DDD' }}></hr>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape26 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape27 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape28 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape29 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape30 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape31 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape32 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape33 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape34 /></div>
        <div className="button" onClick={(e) => handleShapeAdd(e)}><Shape35 /></div>
      </div>
      <hr style={{margin:'20px 0'}}></hr><p style={{textAlign:'center'}}><a style={{color:'#666',textDecoration:'none'}} href="https://www.foundmyself.com/">&copy; Foundmyself.com</a></p>
    </>
  )

}

export default Shapes

